@charset "UTF-8";
.filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 0;
  overflow-x: hidden;
  z-index: 20;
  background-color: white;
  transition: max-width .2s ease-in-out;
  height: 100vh;
  box-shadow: 0 2px 11px 0 grey; }
  .filter h1 {
    font-weight: bold;
    font-size: 16px; }
  .filter__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 19; }
    .filter__overlay.active {
      display: block; }

.filter__display {
  max-width: 100%; }
  @media (min-width: 700px) {
    .filter__display {
      max-width: 450px; } }

.filter-x {
  position: absolute;
  top: 22px;
  right: 18px; }

.filter-innerwrapper {
  width: 296px;
  margin: 50px auto; }

.filter-select {
  margin: 2rem auto;
  text-transform: capitalize; }
  .filter-select div {
    border-color: black;
    color: black;
    font-size: 12px; }
  .filter-select span {
    background-color: black; }
  .filter-select svg {
    color: black; }

.filter-button-wrapper {
  margin: 2rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start; }

.filter-button {
  width: auto;
  min-width: calc(33% - 2rem);
  text-align: left;
  margin: 0 0 1rem 0;
  padding: 0.5rem;
  padding-right: 2rem;
  margin-right: 1rem;
  border: 1px solid transparent;
  position: relative;
  text-transform: capitalize; }
  .filter-button.active {
    border: 1px solid black;
    font-weight: 700; }
    .filter-button.active:hover {
      background-color: #FFBDAD; }
    .filter-button.active:after {
      content: '✕';
      font-weight: 400;
      font-size: 125%;
      position: absolute;
      right: 0.5rem;
      top: 50%;
      transform: translateY(-50%); }
  .filter-button.filter-button-color {
    width: 12.5%;
    min-width: 0;
    margin: 0;
    padding: 0;
    text-align: center;
    border: none;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem; }
    .filter-button.filter-button-color .color-name {
      display: inline-block;
      margin-top: 0.25rem; }
    .filter-button.filter-button-color.active:hover {
      background-color: transparent; }
    .filter-button.filter-button-color.active:after {
      top: 100%;
      left: 50%;
      transform: translateX(-50%); }
    .filter-button.filter-button-color.green .color-swatch {
      background: linear-gradient(to bottom, #01ffec, #dcff01); }
    .filter-button.filter-button-color.blue .color-swatch {
      background: linear-gradient(to bottom, #3001ff, #01edff); }
    .filter-button.filter-button-color.yellow .color-swatch {
      background: linear-gradient(to bottom, #f1ff01, #ffb801); }
    .filter-button.filter-button-color.red .color-swatch {
      background: linear-gradient(to bottom, #ff5401, #ff60a3); }
    .filter-button.filter-button-color.purple .color-swatch {
      background: linear-gradient(to bottom, #ff01e5, #3f01ff); }
    .filter-button.filter-button-color.grey .color-swatch {
      background: linear-gradient(to bottom, #eee, #666); }
    .filter-button.filter-button-color.orange .color-swatch {
      background: linear-gradient(to bottom, #ffcc01, #ff6201); }
    .filter-button.filter-button-color.black .color-swatch {
      background: linear-gradient(to bottom, #666, #000); }
    .filter-button.filter-button-color .color-swatch {
      display: block;
      height: 13rem; }
