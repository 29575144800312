@import 'utils.scss';

//fixed-md flex flex-column justify-center
.Footer {
  overflow: auto;
  width: calc(100% - 24px);
  margin-top: 24px;
  bottom: 0;
  left: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @include greater_than_tablet {
    margin-top: 0;
    left: 0;
    width: 100%;
    position: fixed;
  }
  .CS-logo {
    width: 48px;
    height: 18.41px;
    background-size: 100% auto;
    background-position: center center;
    background-image: url('/cs-logo.svg');
    background-color: white;
    z-index: 10;
    display: relative;
    @include greater_than_tablet {
      float: right;
    }
    &:after {
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      width: 24px;
      height: 100%;
      background-color: white;
    }
    &__text {
      white-space: nowrap;
      padding-bottom: 10px;
      transition: all 0.33s ease;
      font-size: 10px;
      @include greater_than_tablet {
        transform: translateX(25%);
        opacity: 0;
        position: fixed;
        right: 0;
        bottom: 6px;
      }
    }
    &__link {
      width: 100%;
      padding: 0 12px;
      margin: 12px 0;
      align-self: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      @include greater_than_tablet {
        width: auto;
        flex: none;
        margin-left: auto;
      }
      &:hover {
        .CS-logo__text {
          opacity: 1;
          transform: translateX(-75%);
          @include greater_than_tablet {
            transform: translateX(-75%);
          }
          @include greater_than_laptop {
            transform: translateX(-75%);
          }
          @include greater_than_desktop {
            transform: translateX(-65%);
          }
        }
      }
    }
  }
}
