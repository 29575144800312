@import 'utils.scss';

html {
  font-family: helvetica, "helvetica-neue", "arial", sans-serif;
  font-size: 12px;
  font-weight: lighter;
}

#root {
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 132px;
  margin-bottom: 36px;
  @include greater_than_tablet {
    margin-top: 36px;
  }
}

.loading-screen {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1001;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  @include greater_than_desktop {
    font-size: 18px;
    line-height: 22px;
  }
  .loading-screen-gif {
    max-width: 300px;
  }
}
