@import 'utils.scss';

.candidate-wrapper {
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #fffffff2;
  top: 0;
  left: 0;
  z-index: 100;
}

.candidate {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 10;
  background-color: white;

  width: 90%;
  box-shadow: 0 2px 11px 0 #EFEFEF;

  overflow: auto;

  @include laptop {
    max-width: 600px;
  }

  @include desktop {
    max-width: 800px;
  }

  @include fourk {
    max-width: 1130px;
  }

  .candidate-text {
    display: flex;
    flex-direction: row;
    margin: 20px 18px 15px;
    justify-content: space-between;

    @include greater_than_tablet {
      max-width: 360px;
      padding-left: 20px;
      margin: 0 auto 50px;
    }

    @include desktop {
      max-width: 440px;
      padding-left: 30px;
    }

    @include fourk {
      max-width: 590px;
      padding-left: 10px;
    }

    li {
      text-transform: capitalize;
    }
  }

  .responsive-image-wrapper{
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
    display: block;

    @include greater_than_tablet {
      padding-bottom: 65%;
    }

    @include desktop {
      padding-bottom: 55%;
    }

    @include fourk {
      padding-bottom: 50%;
    }

    img {
      width: auto;
      height: auto;
      max-width: 36%;
      max-height: 60%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .candidate-name {

    li:first-child {
      font-weight: bold;
    }
  }
}

.candidate-x {
  position: absolute;
  top: 17px;
  right: 15px;
  z-index: 100;
  img {
    @include greater_than_tablet {
      width: 18px;
      height: 18px;
    }
  }
}

.candidate-specifics {

  @include greater_than_tablet {
    padding-left: 40px;
  }
}
