@import 'utils';

.candidate-card__small, .candidate-card__medium, .candidate-card__large {

  li {
    text-transform: capitalize;
  }

  .responsive-image-wrapper {
    width: 70%;
    height: 0;
    position: relative;
    padding-bottom: 100%;
    position: relative;
    margin-left: 15%;
    z-index: -1;
    display: block;
    img {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .candidate-card-name {

    li:first-child {
      font-weight: bold;
    }
  }
}

.candidate-card__small {
  width: 150px;
  height: 150px;
  margin: 15px auto;

  @include greater_than_tablet {
    width: 85px;
    height: 85px;
    padding: 42px 20px;
    margin: 0 auto;
  }
  .candidate-card-text {
    display: none;
  }
}

.candidate-card__medium {
  width: 175px;
  height: 150px;
  padding: 40px 0;
  // border-right: 1px solid #ECECEC;

  .candidate-card-name {
    text-align: center;

    li:first-child {
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    li:nth-child(2) {
      display: inline;
    }

    li:nth-child(2)::after {
      content: " ";
    }

    li:nth-child(3) {
      display: inline;
      text-transform: capitalize;
    }

    li:nth-child(3)::after {
      content: " ";
    }

    li:nth-child(4) {
      display: inline;
      text-transform: capitalize;
    }
  }

  .candidate-card-specifics {
    display: none;
  }

  .candidate-card-design {
    display: none;
  }
}

.gallery__large {
  display: flex;
  flex-wrap: wrap;
  @include greater_than_tablet {
    margin-top: 84px;
  }
  > a {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    max-width: 800px;
    margin: 0 auto;
    @include greater_than_laptop {
      max-width: 100%;
      margin: 0;
      width: 50%;
      &:nth-child(even) {
        .candidate-card__large {
          margin-left: 0;
        }
      }
      &:nth-child(odd) {
        .candidate-card__large {
          margin-right: 0;
        }
      }
    }
  }
}

.candidate-card__large {
  margin: 0 auto;
  box-shadow: 0 2px 11px 0 #E6E6E6;
  overflow: auto;

  @include laptop {
    max-width: 500px;
  }

  @include desktop {
    max-width: 600px;
  }

  @include fourk {
    max-width: 1130px;
  }

  .candidate-card-text {
    display: flex;
    flex-direction: row;
    margin: 20px 18px 15px;
    justify-content: space-between;
  }
}
