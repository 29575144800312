@import 'utils.scss';

.header {
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  background-color: white;
  box-shadow: 0 2px 11px 0 rgba(230,230,230,0.50);
  font-size: 16px;
  margin-top: 135px;

  @include greater_than_tablet {
    position: fixed;
    height: 40px;
    padding: 30px 0 0;
    margin-top: 0;
  }
}

.header-logo-wrapper {
  height: 135px;
  width: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  @include greater_than_tablet {
    height: auto;
    width: auto;
    position: fixed;
    top: 0;
    transform: translate(-50%, 0);
    left: 50%;
    z-index: 1;
    margin: 16px auto 0;
  }
}

.header-logo {
  width: 42px;
  margin: 45px auto;
  display: block;

  @include greater_than_tablet {
    margin: 0;
  }
}

.header-right-wrapper {
  display: flex;
}

.header-range-wrapper {
  display: none;
  margin: 0 0 0 2rem;

  @include greater_than_tablet {
    display: inline;
  }
    
  .header-range {
    width: 50px;
    display: inline-block;
    height: 11px;
    padding: 5px 0;
    margin: 0 10px;

  }

  .rc-slider-rail {
    height: 1px;
    background-color: black;
  }

  .rc-slider-handle {
    border: 1px solid black;
    height: 11px;
    width: 11px;
  }
}

.header-about-range-wrapper {
  display: flex;
  justify-content: center;

  @include greater_than_tablet {
    display: block;
  }
}

.header-controls {
  width: 85%;
  max-width: 1440px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto 25px;
}

.header-filterbutton {
  img {
    margin: 0 0 0 20px;
  }
}

.header-zoom-buttons button {
  padding: 8px 12px;

  @include greater_than_tablet {
    display: none;
  }
}
