@import 'utils.scss';

.gallery__small {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include tablet {
    width: 640px;
    margin: 0 auto;
  }

  @include greater_than_laptop {
    margin: 0 50px;
  }
}

.gallery__medium {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
}
