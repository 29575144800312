@import 'utils.scss';

.about {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: white;
  overflow: auto;
  opacity: 1;
  transition: opacity .1s ease-in-out;
  &__header {
    font-size: 12px;
    &-page {
      margin-top: 80px;
    }
  }
  &__subheader {
    font-size: 18px;
  }
  .CS-logo {
    &.alt {
      width: 41px;
      height: 15.32px;
      background-size: 100% auto;
      background-position: center center;
      background-image: url('/cs-logo-alt.svg');
    }
  }
  .container {
    width: 90%;
    margin: 0 auto;
    max-width: 1080px;
    display: flex;
    flex-wrap: wrap;
    &:last-child {
      margin-bottom: 4rem;
    }
    &--spacing-top {
      margin-top: 2rem;
      &.large {
        margin-top: 4rem;
      }
    }
  }
  &__column {
    width: 100%;
    @include greater_than_laptop {
      width: calc(60% - 2rem);
      padding-right: 2rem;
    }
    &.thin {
      @include greater_than_laptop {
        width: calc(30% - 2rem);
        padding-right: 2rem;
      }
    }
  }
  .Icon {
    width: 36px;
  }
  &__text {
    width: 100%;
    a {
      display: inline-block;
      border-bottom: 1px solid #a0a0a0;
      padding-bottom: 2px;
      &.no-underline {
        border-bottom: none;
      }
    }
    &-press {
      a {
        display: inline;
        line-height: 2;
      }
    }
    @include greater_than_laptop {
      width: calc(60% - 2rem);
      padding-right: 2rem;
    }
    &-column {
      width: 100%;
      box-sizing: border-box;
      @include greater_than_laptop {
        width: 30%;
      }
    }
  }
  &__image {
    display: none;
    width: 100%;
    max-width: 160px;
    margin: 0 auto;
    img {
      width: 100%;
    }
    @include greater_than_laptop {
      width: 40%;
      display: block;
    }
    &.small {
      display: block;
      margin-top: 80px;
      @include greater_than_laptop {
        display: none;
      }
    }
  }
  h1 {
    margin: 40px auto 20px auto;
    font-size: 18px;
    line-height: 22px;
  }
  h3 {
    font-size: 12px;
    @include greater_than_desktop {
      font-size: 16px;
    }
  }

  p, h2 {
    &:first-child {
      margin-top: 0;
    }
    margin: 20px auto;
    font-size: 14px;
    line-height: 18px;

    @include greater_than_laptop {
      font-size: 12px;
      line-height: 15px;
    }

    @include greater_than_desktop {
      font-size: 14px;
      line-height: 18px;
    }
  }

  li {
    font-size: 14px;
    line-height: 18px;
  }
  span {
    width: 175px;
    font-size: 8px;
    display: block;
  }
}

.about__hidden {
  opacity: 0;
  width: 0;
  height: 0;
}

.about-text-wrapper {
  width: 290px;
  margin: 0 auto;

  @include greater_than_laptop {
    margin: 150px 0 0;
    width: 90%;
  }

  @include greater_than_desktop {
    width: 60%;
  }

  h1 {
    margin: 40px auto;
    font-size: 18px;
    line-height: 22px;

    @include greater_than_desktop {
      font-size: 24px;
      line-height: 26px;
    }
  }

  p, h2 {
    margin: 20px auto;
    font-size: 14px;
    line-height: 18px;

    @include greater_than_laptop {
      font-size: 10px;
      line-height: 12px;
    }

    @include greater_than_desktop {
      font-size: 12px;
      line-height: 14px;
    }

    &:first-child {
      margin: 0 auto;
    }
  }

  li {
    font-size: 14px;
    line-height: 18px;
  }
  span {
    width: 175px;
    font-size: 8px;
    display: block;
  }
}

.about-text-body {
  @include greater_than_laptop {
    column-count: 2;
  }
}

.about-contact {
  li + li {
    margin-top: 1rem;
  }
}

.about-members {
  column-count: 2;

  a {
    border-bottom: 1px solid #a0a0a0;
    padding-bottom: 2px;
  }

  li, h2 {
    @include greater_than_laptop {
      font-size: 10px;
      line-height: 12px;
    }

    @include greater_than_desktop {
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.about-info {
  font-size: 14px;
  text-align: center;
  margin: 70px auto 35px;

  @include greater_than_laptop {
    font-size: 10px;
    line-height: 12px;
    text-align: left;
    margin: 40px auto;
  }

    @include greater_than_desktop {
      font-size: 12px;
      line-height: 14px;
    }

  a {
    margin: 20px auto 0;
    display: inline-block;

    @include greater_than_laptop {
      margin: 0 auto;
    }
  }
}

.about-x {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 30px;
  right: 30px;
  padding: 0;

  img {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
